var exports = {};

var __extends = exports && exports.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };

    return extendStatics(d, b);
  };

  return function (d, b) {
    extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DetailContext = exports.NoopContext = exports.VError = void 0;
/**
 * Error thrown by validation. Besides an informative message, it includes the path to the
 * property which triggered the failure.
 */

var VError =
/** @class */
function (_super) {
  __extends(VError, _super);

  function VError(path, message) {
    var _this = _super.call(this, message) || this;

    _this.path = path; // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work for info about this workaround.

    Object.setPrototypeOf(_this, VError.prototype);
    return _this;
  }

  return VError;
}(Error);

exports.VError = VError;
/**
 * Fast implementation of IContext used for first-pass validation. If that fails, we can validate
 * using DetailContext to collect error messages. That's faster for the common case when messages
 * normally pass validation.
 */

var NoopContext =
/** @class */
function () {
  function NoopContext() {}

  NoopContext.prototype.fail = function (relPath, message, score) {
    return false;
  };

  NoopContext.prototype.unionResolver = function () {
    return this;
  };

  NoopContext.prototype.createContext = function () {
    return this;
  };

  NoopContext.prototype.resolveUnion = function (ur) {};

  return NoopContext;
}();

exports.NoopContext = NoopContext;
/**
 * Complete implementation of IContext that collects meaningfull errors.
 */

var DetailContext =
/** @class */
function () {
  function DetailContext() {
    // Stack of property names and associated messages for reporting helpful error messages.
    this._propNames = [""];
    this._messages = [null]; // Score is used to choose the best union member whose DetailContext to use for reporting.
    // Higher score means better match (or rather less severe mismatch).

    this._score = 0;
  }

  DetailContext.prototype.fail = function (relPath, message, score) {
    this._propNames.push(relPath);

    this._messages.push(message);

    this._score += score;
    return false;
  };

  DetailContext.prototype.unionResolver = function () {
    return new DetailUnionResolver();
  };

  DetailContext.prototype.resolveUnion = function (unionResolver) {
    var _a, _b;

    var u = unionResolver;
    var best = null;

    for (var _i = 0, _c = u.contexts; _i < _c.length; _i++) {
      var ctx = _c[_i];

      if (!best || ctx._score >= best._score) {
        best = ctx;
      }
    }

    if (best && best._score > 0) {
      (_a = this._propNames).push.apply(_a, best._propNames);

      (_b = this._messages).push.apply(_b, best._messages);
    }
  };

  DetailContext.prototype.getError = function (path) {
    var msgParts = [];

    for (var i = this._propNames.length - 1; i >= 0; i--) {
      var p = this._propNames[i];
      path += typeof p === "number" ? "[" + p + "]" : p ? "." + p : "";
      var m = this._messages[i];

      if (m) {
        msgParts.push(path + " " + m);
      }
    }

    return new VError(path, msgParts.join("; "));
  };

  DetailContext.prototype.getErrorDetail = function (path) {
    var details = [];

    for (var i = this._propNames.length - 1; i >= 0; i--) {
      var p = this._propNames[i];
      path += typeof p === "number" ? "[" + p + "]" : p ? "." + p : "";
      var message = this._messages[i];

      if (message) {
        details.push({
          path: path,
          message: message
        });
      }
    }

    var detail = null;

    for (var i = details.length - 1; i >= 0; i--) {
      if (detail) {
        details[i].nested = [detail];
      }

      detail = details[i];
    }

    return detail;
  };

  return DetailContext;
}();

exports.DetailContext = DetailContext;

var DetailUnionResolver =
/** @class */
function () {
  function DetailUnionResolver() {
    this.contexts = [];
  }

  DetailUnionResolver.prototype.createContext = function () {
    var ctx = new DetailContext();
    this.contexts.push(ctx);
    return ctx;
  };

  return DetailUnionResolver;
}();

export default exports;